import React from "react"
import { graphql } from "gatsby"
// features
import Layout from "../features/Layout"
import Seo from "../features/Seo"
import PageSection from "../features/PageSection"
import PageHeader from "../features/PageHeader"

export const query = graphql`query RuneReaderPageQuery {
  hero: file(relativePath: {eq: "rune-reader.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
}
`

const HeaderData = {
  title: "Sorcerous Consultant",
  subHeading: "Stepping into the mythic",
  longText: true,
  middleSpace: "1rem",
  mobileOrnamentSpace: "0",
  links: [
    {
      text: "CONTACT",
      to: "mailto:chaiselevy@gmail.com"
    },
    {
      text: "LEARN MORE",
      to: "#first-section"
    }
  ],
}

const sections = [
  {
    heading: "SORCEROUS CONSULTANT",
    paragraphs: [
      "I offer one-to-one consultations and spellwork for supporting your flourishing. As a practitioner of folk magic I bring a host of magical offerings including specific spellwork, sigil making, banishment, working with the spirits of the dead, supporting relationships with land spirits, spådom, rune casting and herbcraft. The realm of nature, is the realm of magic; these sessions offer clients easy to implement practices for developing lasting relationships with the more-than-human world."
    ],
    cta: {
      pricing: "65 USD per 1.5 hour session",
      text: "BOOK NOW"
    }
  }
]

const RuneReaderPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Sorcerous Consultant" />
      <PageHeader {...HeaderData} hero={data.hero.childImageSharp.gatsbyImageData} />
      {sections.map((section) => (
        <PageSection {...section} />
      ))}
    </Layout>
  )
}

export default RuneReaderPage
