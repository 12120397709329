import styled from "styled-components";

export const EventTiles = styled.div`
  display: flex;
  grid-gap: 24px;
  flex-wrap: wrap;
  margin: 0 2rem;
  text-align: center;
  a {
    transition: 350ms filter ease;
  }
  a:hover {
    filter: drop-shadow(1px 1px 15px rgba(183, 35, 14, 1));
  }
  svg {
    max-width: 440px;
    width: 100%;
    div {
      align-items: space-between;
      justify-content: space-between;
      span {
        padding: 0 15.4%;
        line-height: 1.25;
      }
      span.plaque__primary-text {
        margin-top: .7rem;
        font-family: "Francois One"!important;
      }
      span.plaque__secondary-text {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: .7rem;
      }
    }
  }
`

export const EventsContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 2.4rem;
  align-items: center;
  h2 {
    font-size: 36px;
  }
  p {
    font-size: 18px;
  }
`